"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setdarkMode = exports.refreshAdmin = exports.refreshUser = void 0;
const refreshUser = (user) => {
    return {
        type: 'refresh-user',
        user: user
    };
};
exports.refreshUser = refreshUser;
const refreshAdmin = (admin) => {
    return {
        type: 'refresh-admin',
        admin: admin
    };
};
exports.refreshAdmin = refreshAdmin;
const setdarkMode = (darkMode) => {
    return {
        type: 'set-darkMode',
        darkMode: darkMode
    };
};
exports.setdarkMode = setdarkMode;
