"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageContentReducer = exports.pageContentObject = exports.quoteObject = exports.seperatorObject = exports.youtubeEmbedObject = exports.linkObject = exports.titleObject = exports.imageObject = exports.headerObject = exports.paragraphObject = exports.QuoteComponentClass = exports.SeperatorComponentClass = exports.YoutubeEmbedComponentClass = exports.TitleComponentClass = exports.LinkComponentClass = exports.ImageComponentClass = exports.HeaderComponentClass = exports.ParagraphComponentClass = exports.PageContentClass = void 0;
exports.PageContentClass = 'App\\PageComponents\\PageContent';
exports.ParagraphComponentClass = 'App\\PageComponents\\ParagraphComponent';
exports.HeaderComponentClass = 'App\\PageComponents\\HeaderComponent';
exports.ImageComponentClass = 'App\\PageComponents\\ImageComponent';
exports.LinkComponentClass = 'App\\PageComponents\\LinkComponent';
exports.TitleComponentClass = 'App\\PageComponents\\TitleComponent';
exports.YoutubeEmbedComponentClass = 'App\\PageComponents\\YoutubeEmbedComponent';
exports.SeperatorComponentClass = 'App\\PageComponents\\SeperatorComponent';
exports.QuoteComponentClass = 'App\\PageComponents\\QuoteComponent';
function paragraphObject(original, translated = '', style = {}) {
    return {
        class: exports.ParagraphComponentClass,
        original: original,
        translated: translated,
        style: style
    };
}
exports.paragraphObject = paragraphObject;
function headerObject(original, translated = '', size = 1, style = {}) {
    return {
        class: exports.HeaderComponentClass,
        original: original,
        translated: translated,
        size: size,
        style: style
    };
}
exports.headerObject = headerObject;
function imageObject(original, translated = null) {
    return {
        class: exports.ImageComponentClass,
        original: original,
        translated: translated,
    };
}
exports.imageObject = imageObject;
function titleObject(original, translated = null) {
    return {
        class: exports.TitleComponentClass,
        original: original,
        translated: translated,
    };
}
exports.titleObject = titleObject;
function linkObject(originalLink, originalLabel = null, translatedLink = null, translatedLabel = null) {
    return {
        class: exports.LinkComponentClass,
        originalLink: originalLink,
        originalLabel: originalLabel,
        translatedLink: translatedLink,
        translatedLabel: translatedLabel
    };
}
exports.linkObject = linkObject;
function youtubeEmbedObject(original, translated) {
    return {
        class: exports.YoutubeEmbedComponentClass,
        original: original,
        translated: translated
    };
}
exports.youtubeEmbedObject = youtubeEmbedObject;
function seperatorObject(style) {
    return {
        class: exports.SeperatorComponentClass,
        style: style,
    };
}
exports.seperatorObject = seperatorObject;
function quoteObject(originalQuote, originalLabel = null, translatedQuote = null, translatedLabel = null, style = {}) {
    return {
        class: exports.QuoteComponentClass,
        originalQuote: originalQuote,
        originalLabel: originalLabel,
        translatedQuote: translatedQuote,
        translatedLabel: translatedLabel,
        style: style
    };
}
exports.quoteObject = quoteObject;
function pageContentObject(pageComponents, originalDir, translatedDir) {
    return {
        class: exports.PageContentClass,
        pageComponents: pageComponents,
        originalDir: originalDir,
        translatedDir: translatedDir,
    };
}
exports.pageContentObject = pageContentObject;
function pageContentReducer(page_content, action) {
    switch (action.actionType) {
        case 'set page_content':
            return action.page_content;
        case 'change component':
            return pageContentObject(page_content.pageComponents.map((component, index) => {
                if (index == action.index)
                    return action.component;
                return component;
            }), page_content.originalDir, page_content.translatedDir);
        case 'remove component':
            return pageContentObject(page_content.pageComponents.filter((value, index) => {
                return index != action.index;
            }), page_content.originalDir, page_content.translatedDir);
        case 'add component':
            return pageContentObject([...page_content.pageComponents, action.component], page_content.originalDir, page_content.translatedDir);
        case 'insert component':
            return pageContentObject(page_content.pageComponents.slice(0, action.index).concat(action.component, page_content.pageComponents.slice(action.index)), page_content.originalDir, page_content.translatedDir);
        case 'add components':
            console.log('add components', (action.components));
            return pageContentObject(page_content.pageComponents.concat(action.components), page_content.originalDir, page_content.translatedDir);
        case 'set original dir':
            return pageContentObject(page_content.pageComponents, action.dir, page_content.translatedDir);
        case 'set translated dir':
            return pageContentObject(page_content.pageComponents, page_content.originalDir, action.dir);
        case 'left up component':
            let leftup = [...page_content.pageComponents];
            if (action.index >= 1)
                [leftup[action.index - 1], leftup[action.index]] = [leftup[action.index], leftup[action.index - 1]];
            return pageContentObject(leftup, page_content.originalDir, page_content.translatedDir);
        case 'left down component':
            let leftdown = [...page_content.pageComponents];
            if (action.index < leftdown.length - 1)
                [leftdown[action.index + 1], leftdown[action.index]] = [leftdown[action.index], leftdown[action.index + 1]];
            return pageContentObject(leftdown, page_content.originalDir, page_content.translatedDir);
    }
    return page_content;
}
exports.pageContentReducer = pageContentReducer;
