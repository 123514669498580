"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const INITIAL_STATE = {
    user: null,
    admin: null,
    darkMode: false,
};
scrollBy;
const stateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'refresh-user':
            return Object.assign(Object.assign({}, state), { user: action.user });
        case 'refresh-admin':
            return Object.assign(Object.assign({}, state), { admin: action.admin });
        case 'set-darkMode':
            return Object.assign(Object.assign({}, state), { darkMode: action.darkMode });
        default:
            return state;
    }
};
exports.default = (0, redux_1.combineReducers)({
    state: stateReducer
});
