"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getchapter = exports.getpresection = exports.getnextsection = exports.getelement = exports.getsectionsarray = void 0;
function getsectionsarray(content_table, preIndex) {
    let sections = [];
    content_table === null || content_table === void 0 ? void 0 : content_table.forEach((element, index) => {
        if (element.sections)
            sections = [...sections, ...getsectionsarray(element.sections, preIndex ? `${preIndex}-${index}` : `${index}`)];
        else
            sections = [...sections, Object.assign(Object.assign({}, element), { path: preIndex ? `${preIndex}-${index}` : `${index}` })];
    });
    return sections;
}
exports.getsectionsarray = getsectionsarray;
function getelement(content_table, path) {
    const sections = getsectionsarray(content_table);
    return sections === null || sections === void 0 ? void 0 : sections.find(section => section.path == path);
}
exports.getelement = getelement;
function getnextsection(content_table, path) {
    const sections = getsectionsarray(content_table);
    return sections[sections.findIndex(section => section.path == path) + 1];
}
exports.getnextsection = getnextsection;
function getpresection(content_table, path) {
    const sections = getsectionsarray(content_table);
    return sections[sections.findIndex(section => section.path == path) - 1];
}
exports.getpresection = getpresection;
function getchapter(content_table, path) {
    if (content_table && path) {
        let indexes = path.split('-');
        let elements = content_table;
        let element = null;
        for (let i = 0; i < indexes.length; i++) {
            const index = indexes[i];
            element = elements[index];
            if (element.type == 'chapter')
                elements = element.sections;
        }
        return element;
    }
}
exports.getchapter = getchapter;
